import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'src/utils/axios';
import authService from 'src/network/services/authService';
import ApolloManager from 'src/network/utils/ApolloManager';
import storage from 'src/storage';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  return true;
  // const decoded = jwtDecode(accessToken);
  // const currentTime = Math.floor(Date.now() / 1000);

  // return decoded.expireDate > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
      };
    }
    case 'LOGIN': {
      return {
        ...state,
        isAuthenticated: true
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (companyCode, personalCode, password) => {
    await authService.login(
      companyCode,
      personalCode,
      password,
      'pc'
    );
    ApolloManager.getInstance().init();
    dispatch({
      type: 'LOGIN',
    });
  };

  const logout = () => {
    storage.clear();
    ApolloManager.getInstance().init();
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = storage.getSession();

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          await authService.updateToken();

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;