import React from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  colors,
} from '@material-ui/core';
import {
  RotateCw as RotateCwIcon,
  ChevronLeft,
} from 'react-feather';
import { THEMES } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: colors.common.white
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {},
    borderBottom: "thin solid #EEE"
  },
  toolbar: {
    minHeight: 44,
    width: "100%",
  },
  title: {
    color: "#000",
    fontWeight: "bold",
    width: "100%",
    textAlign: "center"
  },
  backButton: {
    position: "absolute",
    left: 0,
    align: "baseline",
  },
  button: {
    position: "absolute",
    right: 0,
    marginRight: theme.spacing(2),
    color: "#000"
  }
}));

const AppTopBar = ({
  className,
  ...rest
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Button
          className={classes.backButton}
          id="app-bar-back-button"
          component={'a'}
          color="secondary"
          onClick={() => {history.goBack()}}
        >
          <Box display="flex">
            <ChevronLeft />
            <Typography variant="h5">戻る</Typography>
          </Box>
        </Button>
        <Typography id="app-bar-title" className={classes.title} variant="h5"></Typography>
        <IconButton
          color="default"
          className={classes.button}
          onClick={() => {
            window.ReactNativeWebView.postMessage("reload");
          }}
        >
          <RotateCwIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

AppTopBar.propTypes = {
  className: PropTypes.string,
};

export default AppTopBar;
