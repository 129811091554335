import CryptoJS from 'crypto-js';

class Storage {
  setSession = (token) => localStorage.setItem('token', token);

  setUser = (user) => localStorage.setItem('user', CryptoJS.AES.encrypt(JSON.stringify(user), process.env.REACT_APP_SECRET).toString());

  setConstants = (constants) => localStorage.setItem('constants', CryptoJS.AES.encrypt(JSON.stringify(constants), process.env.REACT_APP_SECRET).toString())

  getSession = () => localStorage.getItem('token');

  getUser = () => JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('user'), process.env.REACT_APP_SECRET).toString(CryptoJS.enc.Utf8));

  getConstants = () => JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('constants'), process.env.REACT_APP_SECRET).toString(CryptoJS.enc.Utf8));

  getIsApp = () => Boolean(parseInt(localStorage.getItem('isApp')));

  clear = () => localStorage.clear();
}

const storage = new Storage();
export default storage;