/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles
} from '@material-ui/core';
import {
  PieChart as PieChartIcon,
  User as UserIcon,
  Users as UsersIcon,
  Film as FilmIcon,
  Hash as HashIcon,
  Home as HomeIcon,
  Smile as SmileIcon,
  Clock as ClockIcon,
  Briefcase as BriefcaseIcon,
  BarChart as BarChartIcon,
  FileText as FileTextIcon,
  Award as AwardIcon,
  Archive as ArchiveIcon,
  Settings as SettingsIcon,
  MessageSquare as MessageIcon,
  Star as StarIcon,
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import storage from 'src/storage';

const sections = [
  {
    subheader: '開発管理',
    items: [
      {
        title: 'アカウント切り替え',
        icon: UsersIcon,
        href: '/tools/auth/switch'
      },
      {
        title: '商品管理',
        icon: ArchiveIcon,
        href: '/tools/products'
      },
      {
        title: '企業設定',
        icon: SettingsIcon,
        href: '/tools/setting'
      },
    ]
  },
  {
    subheader: 'マスタ管理',
    items: [
      // {
      //   title: '企業管理',
      //   icon: PieChartIcon,
      //   href: '/tools/companies'
      // },
      {
        title: '業態管理',
        icon: BriefcaseIcon,
        href: '/tools/businesses'
      },
      {
        title: '店舗管理',
        icon: HomeIcon,
        href: '/tools/shops'
      },
      {
        title: 'スタッフ管理',
        icon: UserIcon,
        href: '/tools/users'
      },
    ]
  },
  {
    subheader: '投稿管理',
    items: [
      {
        title: '投稿管理',
        icon: FilmIcon,
        href: '/tools/posts'
      },
      {
        title: 'タグ管理',
        icon: HashIcon,
        href: '/tools/tags'
      },
      {
        title: 'スタンプ管理',
        icon: SmileIcon,
        href: '/tools/stamps'
      },
      {
        title: 'コメント管理',
        icon: MessageIcon,
        href: '/tools/comments'
      },
    ]
  },
  {
    subheader: '成果',
    items: [
      {
        title: '全体売上・視聴数',
        icon: BarChartIcon,
        href: '/tools/analytics'
      },
      {
        title: 'スタッフランキング',
        icon: AwardIcon,
        href: '/tools/rankings'
      },
      {
        title: '投稿ランキング',
        icon: StarIcon,
        href: '/tools/postRankings'
      },
      {
        title: '販売内容',
        icon: FileTextIcon,
        href: '/tools/sales'
      },
    ]
  },
  {
    subheader: '設定',
    items: [
      {
        title: '視聴時間 管理',
        icon: ClockIcon,
        href: '/tools/views'
      },
    ]
  },
];

function renderSection(section, location) {
  return (
    <List
      key={section.subheader}
      subheader={(
        <ListSubheader
          disableGutters
          disableSticky
        >
          {section.subheader}
        </ListSubheader>
      )}
    >
      {renderNavItems({
        items: section.items,
        pathname: location.pathname
      })}
    </List>
  )
};

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  logo: {
    height: 40
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const user = storage.getUser();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo
                className={classes.logo}
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Divider />
        <Box p={2}>
          {sections.map((section) => {
            if (section.subheader === "開発管理") {
              if (user.switchable && user.switchable[0] === 0) {
                return renderSection(section, location);
              }
            } else {
              return renderSection(section, location);
            }
            return null;
          })}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
