import { gql } from '@apollo/client';

export const gqlConstants = {
  name: 'ConstantsItem',
  fragment: gql`
    fragment ConstantsItem on Constants {
      postStatus
      businessStatus
      shopStatus
      userGender
      userStatus
      userRole
      postMovieCommentStatus
      appSetting
      adminSetting
    }
  `
};