import React from 'react';
import clsx from 'clsx';
import {
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Logo = ({className, ...props}) => {
  const classes = useStyles();

  return (
    <img
      className={clsx(className, classes.root)}
      alt="Logo"
      src="/static/logo.svg"
      {...props}
    />
  );
}

export default Logo;
