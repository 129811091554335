import ApolloManager from '../utils/ApolloManager';
import { gql } from '@apollo/client';
import storage from '../../storage';
import { gqlUser } from '../gql/user';
import { gqlConstants } from '../gql/constants';

class AuthService {
  login = (companyCode, personalCode, password, from = '') => new Promise((resolve, reject) => {
    // クエリを定義
    const LOGIN_MUTATION = gql`
      mutation login ($input: loginInput!) {
        login (input: $input) {
          user {
            ...${gqlUser.name}
          }
          constants {
            ...${gqlConstants.name}
          }
          token
          error
        }
      }
      ${gqlUser.fragment}
      ${gqlConstants.fragment}
    `;

    ApolloManager.getInstance().client.mutate({
      variables: {
        input: {
          companyCode: companyCode,
          personalCode: personalCode,
          password: password,
          from: from
        }
      },
      mutation: LOGIN_MUTATION,
    })
    .then((result) => {
      storage.setSession(result.data.login.token);
      storage.setUser(result.data.login.user);
      storage.setConstants(result.data.login.constants);
      console.log(result.data);
      resolve(result.data);
    })
    .catch((error) => {
      reject(error);
    });
  })

  updateToken = () => new Promise((resolve, reject) => {
    // クエリを定義
    const UPDATE_TOKEN_MUTATION = gql`
      mutation updateToken ($input: updateTokenInput!) {
        updateToken (input: $input) {
          user {
            ...${gqlUser.name}
          }
          constants {
            ...${gqlConstants.name}
          }
          token
          error
        }
      }
      ${gqlUser.fragment}
      ${gqlConstants.fragment}
    `;

    ApolloManager.getInstance().client.mutate({
      variables: {
        input: {
          token: storage.getSession(),
        }
      },
      mutation: UPDATE_TOKEN_MUTATION,
    })
    .then((result) => {
      storage.setUser(result.data.updateToken.user);
      storage.setSession(result.data.updateToken.token);
      storage.setConstants(result.data.updateToken.constants);
      resolve(result.data);
    })
    .catch((error) => {
      reject(error);
    });
  })
}

const authService = new AuthService();

export default authService;