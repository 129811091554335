import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import queryString from 'query-string';
import NavBar from './NavBar';
import TopBar from './TopBar';
import AppTopBar from './AppTopBar';
import storage from 'src/storage';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  webWrapper: {
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  appWrapper: {
    marginTop: theme.spacing(5),
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const isApp = storage.getIsApp();
  const location = useLocation();
  const search = location.search;
  const queryParams = queryString.parse(search);
  const appNoHeaderUrls = [
    '/tools/sales'
  ];

  return (
    <div className={classes.root}>
      {!isApp && (
        <>
          <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
          <NavBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />
        </>
      )}
      {isApp && !queryParams.noHeader && !appNoHeaderUrls.includes(location.pathname) && (
        <AppTopBar />
      )}
      <div className={isApp ? (appNoHeaderUrls.includes(location.pathname) || queryParams.noHeader) ? clsx(classes.wrapper) : clsx(classes.wrapper, classes.appWrapper) : clsx(classes.wrapper, classes.webWrapper)}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
