import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { API_URL } from '../../config/api';
import storage from '../../storage';

class ApolloManager {
    static instance = null;

    constructor() {
        this.init();
    }

    init = () => {
        const cache = new InMemoryCache();

        const errorLink = onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors)
            graphQLErrors.map(({ message, locations, path }) =>
              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
              )
            );
          if (networkError) console.log(`[Network error]: ${networkError}`);
        });

        const httpLink = new HttpLink({
            uri: API_URL,
        });

        let link = ApolloLink.from([errorLink, httpLink]);

        const token = storage.getSession();
        if (token) {
            const authLink = setContext((_, { headers }) => {
                return {
                    headers: {
                        ...headers,
                        Authorization: token ? `Bearer ${token}` : "",
                    }
                }
            });
            link = authLink.concat(link);
        }

        this.client = new ApolloClient({
            cache: cache,
            link: link,
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                },
                query: {
                    fetchPolicy: 'no-cache',
                },
            }
        });
    }

    static getInstance() {
        if (ApolloManager.instance === null) {
            ApolloManager.instance = new ApolloManager();
        }
        return this.instance;
    }

    setAuthToken(token) {
        const link = new HttpLink({
            uri: API_URL,
        });

        const authLink = setContext((_, { headers }) => {
            return {
                headers: {
                    ...headers,
                    authorization: token ? `Bearer ${token}` : "",
                }
            }
        });
        this.client = new ApolloClient({
            link: authLink.concat(link),
            cache: new InMemoryCache()
        });
    }
}

export default ApolloManager;