import { gql } from '@apollo/client';
import { gqlPageInfo } from '../gql/connection';
import { convertToGqlJsonParam, convertToGqlStringParam } from '../utils/Util';

export const gqlUser = {
  name: 'UserItem',
  fragment: gql`
    fragment UserItem on User {
      id
      companyId
      company {
        id
        name
        status
      }
      businessIds
      businesses {
        id
        name
      }
      shopId
      shop {
        id
        name
      }
      approvableBusinessIds
      approvableBusinesses {
        id
        name
      }
      approvableShopIds
      approvableShops {
        id
        businessIds
        name
      }
      status
      skipAuth
      role
      code
      displayCode
      name
      displayName
      email
      tel
      gender
      height
      image
      profile
      isRetired
      sns
      switchable
      followNum
    }
  `
};

export const getGqlUser = (id) => {
  return {
    name: "User",
    fragment: gql`
      fragment User on Query {
        user (id: ${id}) {
          ...${gqlUser.name}
        }
      }
      ${gqlUser.fragment}
    `
  }
};

export const getGqlUserList = ({
  first = null,
  after = null,
  last = null,
  before = null,
  orderBy = null,
  where = null
}) => {
  const afterString = convertToGqlStringParam(after);
  const whereString = convertToGqlJsonParam(where);
  const time = new Date();
  const timeString = time.getTime();
  return {
    name: "UserList" + timeString,
    fragment: gql`
      fragment UserList${timeString} on Query {
        users (first: ${first}, after: ${afterString}, last: ${last}, before: ${before} ,orderBy: ${orderBy}, where: "${whereString}") {
          pageInfo {
            ...${gqlPageInfo.name}
          }
          edges {
            node {
              ...${gqlUser.name}
            }
          }
          total
        }
      }
      ${gqlUser.fragment}
      ${gqlPageInfo.fragment}
    `
  }
};