import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    path: '/tools',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/tools/home',
        component: lazy(() => import('src/views/home/DashboardView'))
      },
      {
        exact: true,
        path: '/tools/auth/switch',
        component: lazy(() => import('src/views/auth/SwitchView'))
      },
      {
        exact: true,
        path: '/tools/companies',
        component: lazy(() => import('src/views/company/CompanyListView'))
      },
      {
        exact: true,
        path: '/tools/companies/create',
        component: lazy(() => import('src/views/company/CompanyCreateView'))
      },
      {
        exact: true,
        path: '/tools/businesses',
        component: lazy(() => import('src/views/business/BusinessListView'))
      },
      {
        exact: true,
        path: '/tools/businesses/create',
        component: lazy(() => import('src/views/business/BusinessCreateView'))
      },
      {
        exact: true,
        path: '/tools/businesses/edit/:id',
        component: lazy(() => import('src/views/business/BusinessEditView'))
      },
      {
        exact: true,
        path: '/tools/shops',
        component: lazy(() => import('src/views/shop/ShopListView'))
      },
      {
        exact: true,
        path: '/tools/shops/create',
        component: lazy(() => import('src/views/shop/ShopCreateView'))
      },
      {
        exact: true,
        path: '/tools/shops/edit/:id',
        component: lazy(() => import('src/views/shop/ShopEditView'))
      },
      {
        exact: true,
        path: '/tools/users',
        component: lazy(() => import('src/views/user/UserListView'))
      },
      {
        exact: true,
        path: '/tools/users/create',
        component: lazy(() => import('src/views/user/UserCreateView'))
      },
      {
        exact: true,
        path: '/tools/users/edit/:id',
        component: lazy(() => import('src/views/user/UserEditView'))
      },
      {
        exact: true,
        path: '/tools/share',
        component: lazy(() => import('src/views/share/ShareSelectView'))
      },
      {
        exact: true,
        path: '/tools/products',
        component: lazy(() => import('src/views/product/ProductListView'))
      },
      {
        exact: true,
        path: '/tools/setting',
        component: lazy(() => import('src/views/setting'))
      },
      {
        exact: true,
        path: '/tools/setting/edit/:type',
        component: lazy(() => import('src/views/setting/edit'))
      },
      {
        exact: true,
        path: '/tools/tags',
        component: lazy(() => import('src/views/tag/TagListView'))
      },
      {
        exact: true,
        path: '/tools/tags/create',
        component: lazy(() => import('src/views/tag/TagCreateView'))
      },
      {
        exact: true,
        path: '/tools/tags/edit/:id',
        component: lazy(() => import('src/views/tag/TagCreateView/edit'))
      },
      {
        exact: true,
        path: '/tools/posts',
        component: lazy(() => import('src/views/post/PostListView'))
      },
      {
        exact: true,
        path: '/tools/posts/edit/:id',
        component: lazy(() => import('src/views/post/PostEditView'))
      },
      {
        exact: true,
        path: '/tools/comments',
        component: lazy(() => import('src/views/comment/CommentListView'))
      },
      {
        exact: true,
        path: '/tools/analytics',
        component: lazy(() => import('src/views/analyticsList/DashboardView'))
      },
      {
        exact: true,
        path: '/tools/analyticsList',
        component: lazy(() => import('src/views/analyticsList/DashboardView'))
      },
      {
        exact: true,
        path: '/tools/views',
        component: lazy(() => import('src/views/views/DashboardView'))
      },
      {
        exact: true,
        path: '/tools/stamps',
        component: lazy(() => import('src/views/stamp/StampListView'))
      },
      {
        exact: true,
        path: '/tools/rankings',
        component: lazy(() => import('src/views/ranking/RankingView'))
      },
      {
        exact: true,
        path: '/tools/postRankings',
        component: lazy(() => import('src/views/postRanking/postRankingListView'))
      },
      {
        exact: true,
        path: '/tools/sales',
        component: lazy(() => import('src/views/sales/SalesListView'))
      },
      {
        exact: true,
        path: '/tools',
        component: () => <Redirect to="/tools/home" />
      },
      {
        component: () => <Redirect to="/404" />
      },
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/tools" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
