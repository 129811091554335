import { Buffer } from 'buffer'

export const decodeIdString = (idString) => {
  if (Number.isInteger(idString)) {
    return idString;
  }
  const buffer = new Buffer(idString, 'base64');
  const decodeHash = buffer.toString('ascii');
  const index = decodeHash.indexOf(':')
  return parseInt(decodeHash.slice(index + 1));
}

export const convertToGqlJsonParam = (object) => {
  return JSON.stringify(object).replace(/"/g, "\\\"");
}

export const convertToGqlStringParam = (string) => {
  if (!string) {
    return string;
  }
  return `\"${string}\"`;
}