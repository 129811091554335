import { gql } from '@apollo/client';

export const gqlPageInfo = {
  name: 'PageInfo',
  fragment: gql`
    fragment PageInfo on PageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  `
};